<template>
  <v-dialog
    value="true"
    max-width="500"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('giftcard') }} #{{ this.giftcard.remoteID }}</v-card-title>
      <v-card-subtitle>{{ $t('configureGiftcardOptionsCopyLinkOrSendDirectly') }}</v-card-subtitle>
      <v-container
        fluid
        class="py-0 px-6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    class="py-0 grow pr-0"
                    cols="auto"
                  >
                    <v-text-field
                      hide-details
                      :label="$t('link')"
                      dense
                      outlined
                      v-model="url"
                      disabled
                    />
                  </v-col>
                  <v-col
                    class="py-0 px-2"
                    cols="auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!url"
                          @click="copyUrlToClipboard(url)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('copyLink') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <!--Translations not implemented in giftcard.salescloud -->

        <!--       <v-col
                cols="12"
                class="pb-3"
              >
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="language"
                  :items="languages"
                  item-value="key"
                  item-text="name"
                  :label="$t('language')"
                />
              </v-col>
            -->
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="customerEmail"
                  :label="$t('email')"
                  validate-on-blur
                  :rules="[rules.email]"
                />
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    cols="4"
                    class="pb-3"
                  >
                    <v-btn
                      outlined
                      large
                      block
                      color="red"
                      @click="close"
                    >
                      {{ $t('close') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    class="mr-6"
                  />
                  <v-col
                    cols="4"
                    class="pb-3"
                  >
                    <v-tooltip
                      top
                      :disabled="valuesAreValid"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            outlined
                            large
                            block
                            @click="sendGiftcardEmail"
                            :disabled="!valuesAreValid"
                          >
                            {{ $t('sendGiftcard') }}
                          </v-btn>
                        </div>
                      </template>
                      {{ $t('validEmailRequired') }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  
  export default {
    name: 'OrderReceiptCard',
    props: {
      giftcard: {
        type: Object,
        default: () => {}
      }
    },
    data(){
      return {
        language: null,
        customerEmail: null
      }
    },
    methods: {
      close() {
          this.$emit('close')
      },
      sendGiftcardEmail() {
        this.$store.dispatch('sendGiftcardEmail', {uuid: this.giftcard.uuid, email: this.customerEmail}).then(res => {
          this.$emit('close')
          if(res) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'), 
              subMessage: this.$t('giftcardHasBeenSentToEmail', {email: this.customerEmail})
            })
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('somethingWentWrong')
            })
          }
        }).catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('somethingWentWrong')
          })
        })
      },
      copyUrlToClipboard(url) {
        if(typeof navigator !== 'undefined' && navigator !== null) {
          if(typeof navigator.clipboard !== 'undefined' && navigator.clipboard !== null) {
            navigator.clipboard.writeText(url)
          }
        }
      }
    },
    computed: {
      valuesAreValid(){
        if(this.rules.email(this.customerEmail) !== true) {
          return false
        }
        return true 
      },
      rules() {
        return this.$store.getters.rules
      },  
      user() {
        return this.$store.state.user
      },
      languages() {
        return this.$store.state.languages
      },
      organization() {
        return this.$store.state.organization
      },
      url() {
        if(typeof this.giftcard === 'undefined' || this.giftcard === null) {
          return null
        }   
          return 'https://giftcard.salescloud.is/?uuid=' + this.giftcard.uuid + '&organization=' + this.organization.uuid
      },
    }, 
    created() {
      this.language = this.user.language
      if(this.giftcard.customer && this.giftcard.customer.email) {
        this.customerEmail = this.giftcard.customer.email
      }
    }
  }
  </script>
  